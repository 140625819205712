/* eslint-disable no-console */
import { environment } from '~/constantDefinitions';
import { getDatadogData, isDatadogData } from './getDatadogData';
import { initializeSentryClient, initializeAnonymousSentryClient } from './initializeSentryClient';

if (environment !== 'development') {
  getDatadogData()
    .then((value) => {
      if (isDatadogData(value)) {
        initializeSentryClient(value);
        return undefined;
      }
      console.log('user not logged in');
      initializeAnonymousSentryClient(value);
      return undefined;
    })

    .catch((error) => {
      console.log("Couldn't initialize data dog");
      console.log(error);
    });
}
